import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';

export const TOKEN_KEY = 'energytracer_token';
export const CLAIMS_KEY = 'energytracer_claims';
export const FRESHNESS_KEY = 'freshness';

export const version = '1.16.2';
export const version_acticle = null;

export function validToken() {
  const jwt = getAuthToken();
  const claims = getUserClaims();
  if (!jwt) return false;
  const decoded = getDecoded(jwt);
  if (!checkClaimsKeys(claims)) return false;
  return decoded.exp > dayjs().unix();
}

function checkClaimsKeys(claims) {
  const keys = Object.keys(claims);
  if (keys.length === 0) return false;
  if (!claims.selectedPortfolio) return false;
  if (!claims.selectedOrganization) return false;
  return true;
}

export function getDecoded(jwt) {
  let decoded = null;
  try {
    decoded = jwtDecode(jwt);
  } catch (err) {
    forceLogout();
  }
  return decoded;
}

export function getAuthToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export function getUserClaims() {
  if (localStorage.getItem(CLAIMS_KEY) !== null) {
    try {
      const ret = JSON.parse(localStorage.getItem(CLAIMS_KEY));
      return ret;
    } catch (e) {
      return {};
    }
  } else {
    return {};
  }
}

export function getUserAuthRefreshFlag() {
  if (localStorage.getItem(CLAIMS_KEY) !== null) {
    try {
      const ret = JSON.parse(localStorage.getItem(CLAIMS_KEY));
      if ('authRefreshFlag' in ret) {
        if (ret['authRefreshFlag'] === null) {
          return 0;
        } else {
          return ret['authRefreshFlag'];
        }
      }
      return 0;
    } catch (e) {
      return 0;
    }
  } else {
    return 0;
  }
}

export function forceLogout() {
  removeAuthToken();
  window.location.href = '/';
}

export function removeAuthToken() {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(CLAIMS_KEY);
  localStorage.removeItem(FRESHNESS_KEY);
  return true;
}

//freshness set here as well as refresh checks so that login, register, setauth have an initial freshness set and it doesn't hurt to reset it have the refresh but it also needs to happen before in so that twin calls don't both make a refresh check
export function setAuthToken(response) {
  setFreshness();
  localStorage.setItem(TOKEN_KEY, response['data']['access_token']);
  localStorage.setItem(
    CLAIMS_KEY,
    JSON.stringify(response['data']['user_claims'])
  );
  window.dispatchEvent(new Event('energytracer_storage'));
  return true;
}

export function updateUserClaims(user_claims) {
  localStorage.setItem(CLAIMS_KEY, JSON.stringify(user_claims));
}

export function setJustAuthToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
  return true;
}

export function setFreshness() {
  localStorage.setItem(FRESHNESS_KEY, dayjs().unix());
}

export function checkFreshness() {
  return dayjs().unix() - localStorage.getItem(FRESHNESS_KEY) < 30;
}

const ignore_routes = {
  '/organizations': ['post'],
  '/portfolios': ['post'],
};

export function checkRoutes(config) {
  if (
    Object.keys(ignore_routes).includes(config.url) &&
    ignore_routes[config.url].includes(config.method) in ignore_routes
  ) {
    return false;
  }
  return true;
}
