import React, { useState, useEffect } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import { ApiServiceServerless } from '../../xhr_libs';
import EditEntityActionButtons from '../forms/EditEntityActionButtons';

export default function AdminLicenseModal(props) {
  const { show, onHide, currentLicense, orgInfoTableSetter, showToast } = props;

  const [isSaving, setIsSaving] = useState(false);

  const [referenceNumber, setReferenceNumber] = useState('');

  useEffect(() => {
    setReferenceNumber(currentLicense.reference_number);
  }, [currentLicense, show]);

  useEffect(() => {
    if (!show) {
      setReferenceNumber('');
    }
  }, [show]);

  const handleUpdateOrgLicenseInfo = (e) => {
    e.preventDefault();
    setIsSaving(true);
    const payload = {
      reference_number: referenceNumber,
    };
    ApiServiceServerless.post(
      `/licenses/${currentLicense.id}/org_info`,
      payload
    )
      .then((res) => {
        setIsSaving(false);
        orgInfoTableSetter(currentLicense.id, payload);
        showToast('success', 'Success', 'Your changes were saved.');
      })
      .catch((error) => {
        setIsSaving(false);
        showToast('danger', 'Error', error);
      })
      .finally(() => {
        onHide();
        setIsSaving(false);
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Edit Organization</Modal.Header>
      <Form onSubmit={handleUpdateOrgLicenseInfo}>
        <Modal.Body style={{ maxHeight: '600px', overflowY: 'scroll' }}>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Organization</Form.Label>
                <Form.Control
                  type='text'
                  value={currentLicense['name']}
                  disabled={true}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Organization Sequential</Form.Label>
                <Form.Control
                  type='text'
                  value={currentLicense['id']}
                  disabled={true}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Organization UUID</Form.Label>
                <Form.Control
                  type='text'
                  value={currentLicense['uuid']}
                  disabled={true}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Reference Number</Form.Label>
                <Form.Control
                  type='text'
                  value={referenceNumber}
                  onChange={(e) => {
                    setReferenceNumber(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <EditEntityActionButtons
            onSubmit={handleUpdateOrgLicenseInfo}
            onCancel={onHide}
            submitDisabled={
              currentLicense.reference_number === referenceNumber ||
              referenceNumber === ''
            }
            isSubmitting={isSaving}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
