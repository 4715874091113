import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Container, Tabs, Tab } from 'react-bootstrap';

import { ApiServiceServerless } from '../../xhr_libs';
import Loader from '../../components/Loader';
import WidgetText from '../../components/widgets/WidgetText';
import WidgetPie from '../../components/widgets/WidgetPie';

import { useTabUrl } from '../../helpers/tracked';

import Helmet from 'react-helmet';
import AdminTools from './AdminTools';
import LicenseReportRecipients from './LicenseReportRecipients';
import AdminCostData from './AdminCostData';
import AdminLicenses from './AdminLicenses';
import AdminUsers from './AdminUsers';

const pageTitle = 'Admin Tools';

const styles = {
  row: { marginTop: '1em', marginBottom: '1em' },
};

function Admin(props) {
  const { showToast } = props;

  const [billTotal, setBillTotal] = useState(0);
  const [billSubtotals, setBillSubtotals] = useState([]);
  const [billsThisMonth, setBillsThisMonth] = useState(0);
  const [billsThisYear, setBillsThisYear] = useState(0);
  const [organizationTotal, setOrganizationTotal] = useState(0);
  const [urjanetAccountTotal, setUrjanetAccountTotal] = useState(0);
  const [urjanetTotal, setUrjanetTotal] = useState(0);
  const [userTotal, setUserTotal] = useState(0);
  const [stateObj, setStateObj] = useState({ state: 0 });

  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useTabUrl('admin-dashboard');
  const [activeTabList, setActiveTabList] = useState(['admin-dashboard']);

  const addNewActiveTab = useCallback(
    (tab) => {
      if (tab !== activeTab) {
        setActiveTab(tab);
      }
      if (!activeTabList.includes(tab)) {
        let temp_keys = [...activeTabList];
        temp_keys.push(tab);
        setActiveTabList(temp_keys);
      }
    },
    [activeTab, activeTabList, setActiveTab]
  );

  useEffect(() => {
    addNewActiveTab(activeTab);
  }, [activeTab, addNewActiveTab]);

  useEffect(() => {
    ApiServiceServerless.get('/admin/dashboard')
      .then(({ data }) => {
        setBillsThisMonth(data['bills_this_month']);
        setBillsThisYear(data['bills_this_year']);
        setBillTotal(data['bill_total']);
        setBillSubtotals(data['bill_subtotals']);
        setOrganizationTotal(data['organization_total']);
        setUrjanetAccountTotal(data['urjanet_account_total']);
        setUrjanetTotal(data['urjanet_total']);
        setUserTotal(data['user_total']);
        setStateObj(data['state_obj']);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [showToast]);

  if (isLoading) return <Loader />;
  return (
    <Container className='et-container et-main-tab-content'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Tabs
        id='admin-tabs'
        activeKey={activeTab}
        onSelect={setActiveTab}
        className='et-main-tabs'
      >
        <Tab eventKey='admin-dashboard' title='Dashboard'>
          {activeTabList.includes('admin-dashboard') && (
            <>
              <Row style={styles.row}>
                <Col md={4}>
                  <WidgetText title={'Total Users'} value={userTotal} />
                </Col>
                <Col md={4}>
                  <WidgetText
                    title={'Total Organizations'}
                    value={organizationTotal}
                  />
                </Col>
                <Col md={4}>
                  <WidgetText
                    title={'Total Urjanet Accounts'}
                    value={urjanetAccountTotal}
                  />
                </Col>
              </Row>
              <Row style={styles.row}>
                <Col md={4}>
                  <WidgetPie
                    title={'Bill Distribution (type)'}
                    dataseries={[
                      { name: 'Urjanet Bills', value: urjanetTotal },
                      {
                        name: 'Manual Bills',
                        value: billTotal - urjanetTotal,
                      },
                    ]}
                  />
                </Col>
                <Col md={4}>
                  <WidgetPie
                    title={'Bill Distribution (urjanet)'}
                    dataseries={billSubtotals.map((subtotal) => ({
                      name:
                        subtotal[0].charAt(0).toUpperCase() +
                        subtotal[0].slice(1),
                      value: subtotal[1],
                    }))}
                  />
                </Col>
                <Col md={4}>
                  <Row>
                    <Col md={12}>
                      <WidgetText
                        title={'Bills This Month'}
                        value={billsThisMonth}
                      />
                    </Col>
                    <Col style={{ position: 'absolute', bottom: 0 }} md={12}>
                      <WidgetText
                        title={'Bills This Year'}
                        value={billsThisYear}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={styles.row}>
                <Col md={4}>
                  <WidgetPie
                    title={'Active Climate Divisions (by State)'}
                    dataseries={Object.keys(stateObj).map((el) => ({
                      name: el,
                      value: stateObj[el],
                    }))}
                  />
                </Col>
              </Row>
            </>
          )}
        </Tab>

        <Tab eventKey='admin-licenses' title='Licenses'>
          {activeTabList.includes('admin-licenses') && <AdminLicenses />}
        </Tab>
        <Tab
          eventKey='license-report-recipients'
          title='License Report Recipients'
        >
          {activeTabList.includes('license-report-recipients') && (
            <LicenseReportRecipients showToast={showToast} />
          )}
        </Tab>
        <Tab eventKey='admin-users' title='Users'>
          {activeTabList.includes('admin-users') && (
            <AdminUsers showToast={showToast} />
          )}
        </Tab>
        <Tab eventKey='admin-costs' title='Costs'>
          {activeTabList.includes('admin-costs') && <AdminCostData />}
        </Tab>
        <Tab eventKey='admin-tools' title='Tools'>
          {activeTabList.includes('admin-tools') && <AdminTools />}
        </Tab>
      </Tabs>
    </Container>
  );
}
export default Admin;
