import React from 'react';
import { Redirect, Switch } from 'react-router';

import AppRoute from '../components/AppRoute';

import Resources from '../pages/Resources';
import ForgotPassword from '../pages/ForgotPassword';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import Dashboard from '../pages/Dashboard';
import NotFound from '../pages/NotFound';
import Profile from '../pages/Profile';
import Reports from '../pages/Reports';
import Register from '../pages/Register';
import Activate from '../pages/Activate';
import SetAuth from '../pages/SetAuth';
import SetPassword from '../pages/SetPassword';
import Analysis from '../pages/Analysis';
import ShareCredential from '../pages/ShareCredential';
import UpdateCredential from '../pages/UpdateCredential';
import Admin from '../pages/Admin';
import Tools from '../pages/Tools';
import Portfolios from '../pages/Portfolios/index.js';
import Alerts from '../pages/Alerts';
import DevOAuth from '../pages/DevOAuth';
class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <AppRoute exact noTokenRoute path='/setAuth' component={SetAuth} />
        <AppRoute
          exact
          noTokenRoute
          path='/login'
          routeBackgroundColor={1}
          component={Login}
        />
        <AppRoute
          exact
          noTokenRoute
          path='/register'
          routeBackgroundColor={1}
          component={Register}
        />
        <AppRoute
          exact
          noTokenRoute
          path='/activate'
          routeBackgroundColor={1}
          component={Activate}
        />
        <Redirect from='/home' exact to='/login' />
        <AppRoute
          exact
          noTokenRoute
          path='/forgotPassword'
          routeBackgroundColor={1}
          component={ForgotPassword}
        />
        <AppRoute
          exact
          noTokenRoute
          path='/setPassword/:id/:token'
          routeBackgroundColor={1}
          component={SetPassword}
        />

        <AppRoute
          exact
          path='/shareCredential/:token/:requesterEmail/:requesteeEmail/:provider_type'
          routeBackgroundColor={2}
          component={ShareCredential}
        />
        <AppRoute
          exact
          path='/updateCredential/:token/:requesterEmail/:requesteeEmail/:provider_type'
          routeBackgroundColor={1}
          component={UpdateCredential}
        />
        <AppRoute
          path='/logout'
          authenticated
          logout
          exact
          component={Logout}
        />
        <Redirect from='/' exact to='/dashboard' />
        <AppRoute
          authenticated
          path='/dashboard'
          exact
          organizationSelectRoute
          component={Dashboard}
        />

        <AppRoute
          authenticated
          path='/portfolios'
          exact
          component={Portfolios}
        />
        <AppRoute authenticated path='/profile' exact component={Profile} />
        <AppRoute
          authenticated
          path='/tools'
          exact
          organizationSelectRoute
          component={Tools}
        />
        <AppRoute
          authenticated
          path='/alerts'
          exact
          organizationSelectRoute
          component={Alerts}
        />
        <AppRoute
          authenticated
          path='/resources'
          exact
          organizationSelectRoute
          component={Resources}
        />
        <AppRoute
          authenticated
          path='/reports'
          exact
          organizationSelectRoute
          component={Reports}
        />
        <AppRoute
          authenticated
          path='/analysis'
          exact
          organizationSelectRoute
          component={Analysis}
        />

        {/* Admin */}
        <AppRoute authenticated path='/admin' component={Admin} />
        {/* DEV */}
        {process.env.REACT_APP_ENV === 'dev' && (
          <AppRoute path='/dev_oauth/login' component={DevOAuth} />
        )}
        {/* Finally, catch all unmatched routes */}
        <AppRoute component={NotFound} />
      </Switch>
    );
  }
}

export default Routes;
