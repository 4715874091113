import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, Button } from 'react-bootstrap';
import { cloneDeep, sortBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePen, faUserPen } from '@fortawesome/pro-solid-svg-icons';

import { ApiServiceServerless } from '../../xhr_libs';
import EnergyTracerTable from '../../components/tables';
import LicenseCell from './LicenseCell';
import AdminOrgLicenseModal from '../../components/modals/AdminOrgLicenseModal';
import AdminOrgInfoModal from '../../components/modals/AdminOrgInfoModal';
import Loader from '../../components/Loader';
import AppContext from '../../contexts/app-context';
import FilterInput from '../../components/FilterInput';

const styles = {
  row: { marginTop: '1em', marginBottom: '1em' },
  iconStyle: {
    fontSize: '15px',
  },
};

export default function AdminLicenses() {
  const { setActivePortfolio, setActiveOrganization, showToast } =
    useContext(AppContext);

  const history = useHistory();

  const [licenses, setLicenses] = useState([]);
  const [currentLicense, setCurrentLicense] = useState({
    id: 0,
    name: 'none',
    uuid: '',
    licenses: {},
  });

  const [organizationFilterValue, setOrganizationFilterValue] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [showLicenseModal, setShowLicenseModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    ApiServiceServerless.get('/licenses')
      .then(({ data }) => {
        setLicenses(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });
  }, []);

  const licenseTableSetter = (org_id, license_id, type, active, inactive) => {
    setLicenses((prev) =>
      prev.map((org) => {
        if (org['id'] !== org_id) return org;
        let newOrg = cloneDeep(org);
        newOrg['licenses'][type]['id'] = license_id;
        newOrg['licenses'][type]['active'] = active;
        newOrg['licenses'][type]['inactive'] = inactive;
        return newOrg;
      })
    );
  };

  const orgInfoTableSetter = (org_id, org_item) => {
    setLicenses((prev) =>
      prev.map((org) => {
        if (org['id'] !== org_id) return org;
        let newOrg = cloneDeep(org);
        Object.keys(org_item).forEach((key) => {
          newOrg[key] = org_item[key];
        });
        return newOrg;
      })
    );
  };

  const LICENSE_COLUMNS = [
    {
      dataField: 'name',
      text: 'Organization',
      formatter: (cell, row) => (
        <div
          className={'et-link'}
          onClick={() => {
            setActivePortfolio({ id: -1, name: '' });
            setActiveOrganization({ id: row.id, name: row.name });
            history.push('/resources');
          }}
        >
          {row.name}
        </div>
      ),
    },
    {
      dataField: 'license1',
      formatter: (_cell, row) => (
        <LicenseCell license={row['licenses']['analysis']} />
      ),
      text: 'Analysis',
    },
    {
      dataField: 'license2',
      formatter: (_cell, row) => (
        <LicenseCell license={row['licenses']['urjanet']} />
      ),
      text: 'Urjanet',
    },
    {
      dataField: 'license3',
      formatter: (_cell, row) => (
        <LicenseCell license={row['licenses']['plug']} />
      ),
      text: 'Plug',
    },
    {
      dataField: 'license4',
      formatter: (_cell, row) => (
        <LicenseCell license={row['licenses']['ghg']} />
      ),
      text: 'GHG',
    },
    {
      dataField: 'license5',
      formatter: (_cell, row) => (
        <LicenseCell license={row['licenses']['alerts']} />
      ),
      text: 'Alerts',
    },
    {
      dataField: 'license6',
      formatter: (_cell, row) => (
        <LicenseCell license={row['licenses']['solar']} />
      ),
      text: 'Solar',
    },
    {
      dataField: 'actions',
      formatter: (_cell, row) => (
        <Row>
          <Button
            variant='link'
            onClick={(e) => {
              setCurrentLicense(row);
              setShowLicenseModal(true);
            }}
          >
            <FontAwesomeIcon style={styles.iconStyle} icon={faFilePen} />
          </Button>
          <Button
            variant='link'
            onClick={(e) => {
              setCurrentLicense(row);
              setShowInfoModal(true);
            }}
          >
            <FontAwesomeIcon style={styles.iconStyle} icon={faUserPen} />
          </Button>
        </Row>
      ),
      text: 'Actions',
    },
  ];

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Row style={styles.row}>
            <Col md={12}>
              <Row>
                <FilterInput
                  label='Filter Organization'
                  filterValue={organizationFilterValue}
                  setFilterValue={setOrganizationFilterValue}
                  size={3}
                />
              </Row>
              <EnergyTracerTable
                data={sortBy(
                  licenses.filter((org) =>
                    org.name
                      .toLowerCase()
                      .includes(organizationFilterValue.toLowerCase())
                  ),
                  ['name']
                )}
                columns={LICENSE_COLUMNS}
                keyField='id'
              />
            </Col>
          </Row>
          <AdminOrgLicenseModal
            show={showLicenseModal}
            onHide={() => setShowLicenseModal(false)}
            currentLicense={currentLicense}
            licenseTableSetter={licenseTableSetter}
          />
          <AdminOrgInfoModal
            show={showInfoModal}
            onHide={() => setShowInfoModal(false)}
            currentLicense={currentLicense}
            orgInfoTableSetter={orgInfoTableSetter}
            showToast={showToast}
          />
        </>
      )}
    </>
  );
}
