import React, { useEffect, useState } from 'react';
import { Button, Form, Col } from 'react-bootstrap';

import { ApiServiceServerless } from '../../xhr_libs';

const ACCOUNT_UPDATE_OPTIONS = {
  credential: {
    option: 'Account',
    url: '/admin/update_accounts',
  },
  account: {
    option: 'Organization',
    url: '/admin/update_org_accounts',
  },
};

const BILL_UPDATE_OPTIONS = {
  resource: {
    org: 'Organization',
    account: 'Account',
  },
  method: {
    1: 'Read Date',
    2: 'Bill Date',
  },
};

export default function AdminUtilityAccountTools(props) {
  const { showToast, isGenerating, setIsGenerating } = props;

  const [updateAccountOption, setUpdateAccountOption] = useState(null);
  const [updateAccountId, setUpdateAccountId] = useState(null);

  const [updateBillResourceOption, setUpdateBillResourceOption] =
    useState(null);
  const [updateBillMethodOption, setUpdateBillMethodOption] = useState(null);
  const [updateBillResourceId, setUpdateBillResourceId] = useState(null);

  useEffect(() => {
    setUpdateAccountId(null);
  }, [updateAccountOption]);

  useEffect(() => {
    setUpdateBillResourceId(null);
  }, [updateBillResourceOption]);

  const handleUpdateResource = () => {
    setIsGenerating(true);
    let url =
      ACCOUNT_UPDATE_OPTIONS[updateAccountOption]['url'] +
      '/' +
      updateAccountId;

    ApiServiceServerless.get(url)
      .then(() => {
        showToast(
          'success',
          'Success',
          'Update in progress. Please check back in a few minutes'
        );
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        setIsGenerating(false);
      });
  };

  const handleUpdateBill = () => {
    setIsGenerating(true);
    let url = `admin/update_bill_dates/${updateBillResourceId}/${updateBillResourceOption}/${updateBillMethodOption}`;
    ApiServiceServerless.get(url)
      .then(() => {
        showToast(
          'success',
          'Success',
          'Repull in progress. Please check back in a few minutes'
        );
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        setIsGenerating(false);
      });
  };

  return (
    <>
      <br></br>
      <br></br>
      <h3>Utility Account Tools</h3>
      <h5>Update Account Max Date</h5>
      <Form>
        <Form.Row>
          <Form.Group as={Col} md={3}>
            <Form.Label>Update Option</Form.Label>
            <Form.Control
              as='select'
              onChange={(e) => {
                setUpdateAccountOption(e.target.value);
              }}
              value={updateAccountOption}
            >
              <option hidden key={'select-option'} value={null}></option>
              {Object.keys(ACCOUNT_UPDATE_OPTIONS).map((key) => (
                <option key={key} value={key}>
                  {ACCOUNT_UPDATE_OPTIONS[key]['option']}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {updateAccountOption && (
            <Form.Group as={Col}>
              <Form.Label>
                {ACCOUNT_UPDATE_OPTIONS[updateAccountOption]['option']} ID
              </Form.Label>
              <Form.Control
                value={updateAccountId ? updateAccountId : ''}
                onChange={(e) => setUpdateAccountId(e.target.value)}
              />
            </Form.Group>
          )}
          <Form.Group as={Col} md={2}>
            <Form.Label>Action</Form.Label>
            <Form.Control
              as={Button}
              disabled={
                !updateAccountOption || !updateAccountId || isGenerating
              }
              onClick={() => {
                handleUpdateResource();
              }}
            >
              Update Resource
            </Form.Control>
          </Form.Group>
        </Form.Row>
      </Form>
      <h5>Update Bill Dates</h5>
      <Form>
        <Form.Row>
          <Form.Group as={Col} md={3}>
            <Form.Label>Update Option</Form.Label>
            <Form.Control
              as='select'
              onChange={(e) => {
                setUpdateBillResourceOption(e.target.value);
              }}
              value={updateBillResourceOption}
            >
              <option hidden key={'select-option'} value={null}></option>
              {Object.keys(BILL_UPDATE_OPTIONS['resource']).map((key) => (
                <option key={key} value={key}>
                  {BILL_UPDATE_OPTIONS['resource'][key]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {updateBillResourceOption && (
            <Form.Group as={Col}>
              <Form.Label>
                {BILL_UPDATE_OPTIONS['resource'][updateBillResourceOption]} ID
              </Form.Label>
              <Form.Control
                value={updateBillResourceId ? updateBillResourceId : ''}
                onChange={(e) => setUpdateBillResourceId(e.target.value)}
              />
            </Form.Group>
          )}
          <Form.Group as={Col} md={3}>
            <Form.Label>Update Method</Form.Label>
            <Form.Control
              as='select'
              onChange={(e) => {
                setUpdateBillMethodOption(e.target.value);
              }}
              value={updateBillMethodOption}
            >
              <option hidden key={'select-option'} value={null}></option>
              {Object.keys(BILL_UPDATE_OPTIONS['method']).map((key) => (
                <option key={key} value={key}>
                  {BILL_UPDATE_OPTIONS['method'][key]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md={2}>
            <Form.Label>Action</Form.Label>
            <Form.Control
              as={Button}
              disabled={
                !updateBillResourceId ||
                !updateBillResourceOption ||
                !updateBillMethodOption ||
                isGenerating
              }
              onClick={() => {
                handleUpdateBill();
              }}
            >
              Update Resource
            </Form.Control>
          </Form.Group>
        </Form.Row>
      </Form>
    </>
  );
}
