import React, { useState, useCallback, useEffect } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import dayjs from 'dayjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMeter,
  faBuilding,
  faSyncAlt,
} from '@fortawesome/pro-solid-svg-icons';

import { ApiServiceServerless } from '../../../xhr_libs';
import AddUtilityAccountModal from '../../../components/modals/UtilityAccountModals/AddUtilityAccountModal';
import AddBuildingModal from '../../../components/modals/BuildingModals/AddBuildingModal';
import EnergyTracerTable from '../../../components/tables';
import { useOrganizationRoles } from '../../../helpers/checkRoles';
import {
  getUserPreference,
  setUserPreference,
} from '../../../helpers/user-preferences';
import Loader from '../../../components/Loader';
import FilterInput from '../../../components/FilterInput';
import { UTILITY_TYPES_OPTS } from '../../../helpers/utility-types';

const styles = {
  iconStyle: {
    fontSize: '21px',
    margin: '0 0.35em',
    verticalAlign: 'text-bottom',
    cursor: 'pointer',
    color: 'var(--et_tab_grey)',
    opacity: '1.0',
  },
  formLabelStyles: {
    fontSize: '0.9rem',
    marginBottom: '0',
  },
};

const getUniqueBuildings = (rows) => [
  ...new Map(rows.map((row) => [row.building.id, row.building])).values(),
];

const formatAccountType = (acctType) =>
  acctType
    ? acctType.charAt(0).toUpperCase() + acctType.substr(1).toLowerCase()
    : '';

const concatEmptyBuildingsToAccts = (accts, emptyBuildings) =>
  emptyBuildings
    .map((bldg) => ({
      buildings: [{ id: bldg.id, name: bldg.name }],
    }))
    .concat(accts);

const formatTableData = (
  accounts,
  buildingFilterValue,
  accountFilterValue,
  utilityFilterValue
) => {
  let filtered_accounts = [...accounts];
  //utility filter
  if (utilityFilterValue !== 0) {
    filtered_accounts = filtered_accounts.filter(
      (acc) =>
        acc.account_type &&
        acc.account_type.toLowerCase() ===
          UTILITY_TYPES_OPTS[utilityFilterValue].toLowerCase()
    );
  }
  // building filter
  if (buildingFilterValue !== '') {
    filtered_accounts = filtered_accounts.filter(
      (acc) =>
        acc.buildings.filter((building) =>
          building.name
            .toLowerCase()
            .includes(buildingFilterValue.toLowerCase())
        ).length > 0
    );
  }
  // account filter
  if (accountFilterValue !== '') {
    filtered_accounts = filtered_accounts.filter(
      (acc) =>
        acc.account_number &&
        acc.account_number
          .toLowerCase()
          .includes(accountFilterValue.toLowerCase())
    );
  }

  const tableRows = filtered_accounts.flatMap((acct) =>
    // create a unique row per account-building pair
    acct.buildings.map((building) => ({
      ...acct,
      building: building,
      account_number: acct.account_number || '',
      account_type: formatAccountType(acct.account_type),
      last_updated: acct.max_bill_date,
      rowKey: `acct-${acct.id}-type-${acct.account_type}-bldg-${building.id}`,
    }))
  );
  return indicateFirstRows(tableRows);
};

const indicateFirstRows = (rows) => {
  const checkIfFirstAccountRow = (row, idx) => {
    if (!row.id || row.id !== currentAccountId) {
      currentAccountId = row.id;
      currentType = row.account_type;
      row.firstAccountRow = true;
      row.firstTypeRow = true;
      return row;
    } else row.firstAccountRow = false;
  };

  const checkIfFirstTypeRow = (row) => {
    if (row.account_type && row.account_type !== currentType) {
      row.firstTypeRow = true;
      currentType = row.account_type;
    } else row.firstTypeRow = false;
    return row;
  };

  let currentAccountId,
    currentType = null;
  return rows.map(
    (row, idx) => checkIfFirstAccountRow(row, idx) || checkIfFirstTypeRow(row)
  );
};

const getTableColumns = (
  setSelectedResource,
  setBuildingInfo,
  setUtilityAccountInfo
) => [
  {
    dataField: 'account_number',
    text: 'Account #',
    formatter: (cell, row) =>
      buildingAccountLinkFormatter(
        row.account_number
          ? row.firstAccountRow || row.firstTypeRow
            ? row.account_number
            : ''
          : ['No Accounts'],
        row.account_number
          ? row.firstAccountRow || row.firstTypeRow
            ? true
            : false
          : false,
        () => {
          setSelectedResource('utilityAccount');
          setUtilityAccountInfo({ id: row.id, name: row.account_number });
          setBuildingInfo({
            id: row.buildings[0].id,
            name: row.buildings[0].name,
          });
          setUserPreference('last_selected_account', row.id);
        }
      ),
    style: (_cell, row) => columnStyle(row, 'account_number'),
    classes: (_cell, row) => columnClasses(row),
  },
  {
    dataField: 'account_type',
    text: 'Utility Type',
    style: (_cell, row) => columnStyle(row, 'account_type'),
    classes: (_cell, row) => columnClasses(row),
    formatter: (_cell, row) => (row.firstTypeRow ? row.account_type : ''),
  },
  {
    dataField: 'last_updated',
    text: 'Last Updated',
    formatter: (_cell, row) =>
      !row.firstTypeRow || !row.account_number ? (
        ''
      ) : !row.last_updated ? (
        <span className='text-secondary'>No Bills</span>
      ) : (
        dayjs.utc(row.last_updated).format('YYYY-MM-DD')
      ),
    style: (_cell, row) => columnStyle(row, 'last_updated'),
    classes: (_cell, row) => columnClasses(row),
  },
  {
    dataField: 'building.name',
    text: 'Building',
    formatter: (cell, row) =>
      buildingAccountLinkFormatter(row.building.name, true, () => {
        setSelectedResource('building');
        setBuildingInfo({ id: row.building.id, name: row.building.name });
        setUtilityAccountInfo({ id: null });
      }),
    style: (_cell, row) => columnStyle(row, 'building'),
    classes: (_cell, row) => columnClasses(row),
  },
];

const buildingAccountLinkFormatter = (title, isLink, setter) => {
  return (
    <div
      className={isLink ? 'et-link' : ''}
      onClick={() => {
        setter();
      }}
      disabled={!isLink}
    >
      {title}
    </div>
  );
};

const columnClasses = (row) => {
  return row.id === getUserPreference('last_selected_account')
    ? 'et-table-selected-row'
    : '';
};

const columnStyle = (row, dataField) => {
  const styles = {
    borderless: {
      borderBottomWidth: 0,
      borderTopWidth: 0,
    },
    borderThick: {
      borderTopWidth: 2,
    },
  };
  let ret_styles = {};
  if (row.firstAccountRow || row.firstTypeRow) {
    ret_styles = addStyles(ret_styles, styles.borderThick);
  }
  if (
    (dataField === 'account_number' ||
      ['account_type', 'last_updated'].includes(dataField)) &&
    !row.firstTypeRow
  ) {
    ret_styles = addStyles(ret_styles, styles.borderless);
  }
  return ret_styles;
};

const addStyles = (current, styles) => {
  Object.keys(styles).forEach((style) => {
    current[style] = styles[style];
  });
  return current;
};

export default function OrganizationBuildingAccounts(props) {
  const {
    organizationId,
    userSelectedOrganization,
    userSelectedOrganizationDetails,
    setSelectedResource,
    buildingInfo,
    setBuildingInfo,
    setUtilityAccountInfo,
    OBAUpdate,
    handleUpdateTables,
    OBADateUpdates,
    setOBADateUpdates,
    buildingFilterValue,
    setBuildingFilterValue,
    accountFilterValue,
    setAccountFilterValue,
    utilityFilterValue,
    setUtilityFilterValue,
    visible,
    showToast,
  } = props;

  const [accounts, setAccounts] = useState([]);
  const [emptyBuildings, setEmptyBuildings] = useState([]);

  const [buildings, setBuildings] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showBuildingModal, setShowBuildingModal] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [defaultBuildings, setDefaultBuildings] = useState([]);

  const [lastOBAUpdate, setLastOBAUpdate] = useState(0);
  const [manualRefresh, setManualRefresh] = useState(0);

  const buildingCreateAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'building',
    'create'
  );
  const utilityAccountCreateAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'utility_account',
    'create'
  );

  const tableColumns = getTableColumns(
    setSelectedResource,
    setBuildingInfo,
    setUtilityAccountInfo
  );

  const refreshTableData = useCallback(
    (
      accounts,
      emptyBuildings,
      buildingFilterValue,
      accountFilterValue,
      utilityFilterValue
    ) => {
      const localTableData = formatTableData(
        concatEmptyBuildingsToAccts(accounts, emptyBuildings),
        buildingFilterValue,
        accountFilterValue,
        utilityFilterValue
      );
      setTableData(localTableData);
      setBuildings(getUniqueBuildings(localTableData));
    },
    []
  );

  useEffect(() => {
    refreshTableData(
      accounts,
      emptyBuildings,
      buildingFilterValue,
      accountFilterValue,
      utilityFilterValue
    );
  }, [
    accounts,
    emptyBuildings,
    refreshTableData,
    buildingFilterValue,
    accountFilterValue,
    utilityFilterValue,
  ]);

  const setTablePosition = useCallback(() => {
    setTimeout(() => {
      const row = document.querySelector('.et-table-selected-row');
      if (row) {
        const table = document.querySelector('.et-table-wrapper-short');
        if (table) {
          table.scrollTop = row.parentElement.offsetTop;
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    setTablePosition();
  }, [setTablePosition, visible]);

  const handleRefreshGetData = useCallback(
    async (org_id) => {
      setIsLoading(true);
      const [{ data: accounts }, { data: emptyBuildings }] = await Promise.all([
        ApiServiceServerless.get(`/utility_accounts/organization/${org_id}`, {
          authorization_id: org_id,
        }),
        ApiServiceServerless.get(
          `/buildings/organization/${org_id}?empty=true`,
          {
            authorization_id: org_id,
          }
        ),
      ]);
      setAccounts(accounts);
      setEmptyBuildings(emptyBuildings);
      setIsLoading(false);
      setTablePosition();
    },
    [setTablePosition]
  );

  useEffect(() => {
    setIsLoading(true);
    setAccounts([]);
    setEmptyBuildings([]);
    setBuildings([]);
    setTableData([]);
    if (organizationId) {
      handleRefreshGetData(organizationId);
    } else {
      setIsLoading(false);
    }
  }, [handleRefreshGetData, organizationId]);

  const handleSetNewBuilding = (data) => {
    setEmptyBuildings((prev) => [...prev, data]);
    handleUpdateTables(2);
  };

  const handleSetAccounts = (data) => {
    setAccounts((prev) => [...prev, data]);
    handleUpdateEmptyBuildings(data);
    handleUpdateTables(2);
  };

  const handleUpdateEmptyBuildings = (acc) => {
    setEmptyBuildings((prev) =>
      prev.filter((building) => !acc.building_ids.includes(building.id))
    );
  };

  useEffect(() => {
    if (OBAUpdate !== lastOBAUpdate && visible && organizationId) {
      handleRefreshGetData(organizationId);
      setLastOBAUpdate(OBAUpdate);
    }
  }, [OBAUpdate, lastOBAUpdate, visible, organizationId, handleRefreshGetData]);

  useEffect(() => {
    if (manualRefresh > 0 && organizationId) {
      handleRefreshGetData(organizationId);
      setManualRefresh(0);
    }
  }, [manualRefresh, organizationId, handleRefreshGetData]);

  useEffect(() => {
    if (accounts.length > 0 && OBADateUpdates.length > 0 && visible) {
      OBADateUpdates.forEach((update) => {
        const acc = accounts.find((acc) => acc.id === update.account_id);
        if (
          acc &&
          new Date(acc.max_bill_date) < new Date(update.new_max_date)
        ) {
          setAccounts((prev) =>
            prev.map((acc) => {
              if (acc.id === update.account_id)
                return { ...acc, max_bill_date: update.new_max_date };
              return acc;
            })
          );
        }
      });
      setOBADateUpdates([]);
    }
  }, [accounts, visible, OBADateUpdates, setOBADateUpdates]);

  return (
    <>
      <Row style={{ marginTop: '-0.5em' }}>
        <Col xs={12}>
          <>
            {isLoading && <Loader />}
            {!isLoading && (
              <div>
                <Row>
                  <FilterInput
                    label='Filter Account'
                    filterValue={accountFilterValue}
                    setFilterValue={setAccountFilterValue}
                    size={3}
                  />
                  <FilterInput
                    label='Filter Building'
                    filterValue={buildingFilterValue}
                    setFilterValue={setBuildingFilterValue}
                    size={3}
                  />
                  <Form.Group as={Col} md={2}>
                    <Form.Label style={styles.formLabelStyles}>
                      Utility Type
                    </Form.Label>
                    <Form.Control
                      as='select'
                      onChange={(e) =>
                        setUtilityFilterValue(parseInt(e.target.value))
                      }
                      value={utilityFilterValue}
                    >
                      {Object.keys(UTILITY_TYPES_OPTS).map((key) => (
                        <option key={key} value={key}>
                          {UTILITY_TYPES_OPTS[key]}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Col md={4}>
                    <div
                      style={{
                        position: 'absolute',
                        right: '2.5%',
                        bottom: '35%',
                      }}
                    >
                      <FontAwesomeIcon
                        style={styles.iconStyle}
                        icon={faSyncAlt}
                        title={'Refresh Table'}
                        onClick={() => setManualRefresh(1)}
                      />
                      {buildingCreateAccess && (
                        <FontAwesomeIcon
                          style={styles.iconStyle}
                          icon={faBuilding}
                          title={'Add Building'}
                          onClick={() => setShowBuildingModal(true)}
                        />
                      )}
                      {utilityAccountCreateAccess && (
                        <FontAwesomeIcon
                          style={styles.iconStyle}
                          icon={faMeter}
                          title={'Add Utility Account'}
                          onClick={() => setShowAccountModal(true)}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <EnergyTracerTable
                  data={tableData}
                  columns={tableColumns}
                  keyField={'rowKey'}
                  enableScroll={true}
                  wrapperStyle={'et-table-wrapper-short'}
                />
              </div>
            )}
            {!tableData.length && !isLoading && (
              <div style={{ margin: '30px' }}>
                {`There are no buildings within the organization. Please create a new building to add utility accounts.`}
              </div>
            )}
          </>
        </Col>
      </Row>
      <div style={{ marginBottom: '20px' }} />
      <AddUtilityAccountModal
        organization={userSelectedOrganization}
        buildings={buildings}
        defaultBuildings={defaultBuildings}
        setDefaultBuildings={setDefaultBuildings}
        show={showAccountModal}
        onHide={() => {
          setShowAccountModal(false);
          setDefaultBuildings([]);
        }}
        openAccountModal={() => setShowAccountModal(true)}
        handleSetAccount={handleSetAccounts}
        parentIsLoading={isLoading}
        buildingInfo={buildingInfo}
        setSelectedResource={setSelectedResource}
        setBuildingInfo={setBuildingInfo}
        setUtilityAccountInfo={setUtilityAccountInfo}
        showToast={showToast}
      />
      <AddBuildingModal
        organization={userSelectedOrganization}
        show={showBuildingModal}
        onHide={() => setShowBuildingModal(false)}
        openBuildingModal={() => {
          setShowBuildingModal(true);
        }}
        handleSetBuilding={handleSetNewBuilding}
        setDefaultBuildings={setDefaultBuildings}
        openAccountModal={() => setShowAccountModal(true)}
        addAccountOption={true}
        setSelectedResource={setSelectedResource}
        setBuildingInfo={setBuildingInfo}
        setUtilityAccountInfo={setUtilityAccountInfo}
        showToast={showToast}
      />
    </>
  );
}
